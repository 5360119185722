import { post } from "../util/http"
/**
 * 登录
 * @param  params 
 */
export const LoginRequest = params => post(`/api/user/login`, params)
/**
 * 加载用户数量信息
 * @param  params 
 */
export const SelectUserNumber = params => post(`/api/user/selectUserNumber`, params)

/**
 * 注册
 * @param  params 
 */
export const RegisterRequest = params => post(`/api/user/register`, params)

/**
 * 密码找回
 * @param  params 
 */
export const RegisterUppwd = params => post(`/api/user/uppwd`, params)
/**
 * 修改用户信息
 * @param  params 
 */
export const RegisterUpdateUser = params => post(`/api/user/update`, params)
/**
 * 查询用户列表
 * @param  params 
 */
export const SelectUserPage = params => post(`/api/user/selectPage`, params)
/**
 * 修改用户状态
 * @param  params 
 */
export const UpdateUserStatus = params => post(`/api/user/updateUserStatus`, params)

/**
 * 添加授权次数
 * @param  params 
 */
export const addAuthTimes = params => post(`/api/user/addAuthTimes`, params)

/**
 * 分页查询key
 */
export const SelectKeyPage = params => post('/api/key/selectPage', params)

/**
 * 添加key
 */
export const addKey = params => post('/api/key/add', params)

/**
 * 绑定
 */
export const binding = params => post('/api/key/binding', params)


/**
 * 解绑
 */
export const unbinding = params => post('/api/key/unbinding', params)


/**
 * 延长授权
 */
export const extendAuth = params => post('/api/key/extendAuth', params)
/**
 * 添加授权
 */
export const addAuth = params => post('/api/key/addAuth', params)
/**
 * 更新key 类型
 */
export const UpdateKeyType = params => post('/api/key/upKeyType', params)
/**
 * 修改状态
 */
export const updateStatus = params => post('/api/key/updateStatus', params)
/**
 * 删除
 */
export const deleteKey = params => post('/api/key/delete', params)

/**
 * 获取配置
 */
export const getSetting = params => post('/api/setting/get', params)

/**
 * 设置配置
 */
export const setSetting = params => post('/api/setting/save', params)

/**
 * 查询日志列表
 * @param  params 
 */
export const SelectLogsPage = params => post(`/api/logs/selectPage`, params)

/**
* 查看版本列表
* @param  params 
*/
export const SelectVersionPage = params => post(`/api/version/selectPage`, params)
/**
* 添加
*/
export const addVersion = params => post('/api/version/insert', params)
/**
 * 删除
 */
export const deleteVersion = params => post('/api/version/delete', params)

/**
* 获取
*/
export const getVersion = params => post('/api/version/get', params)

/**
 * 代理IP信息
 * 分页查询key
 */
export const SelectPageProxyIPInfo = params => post('/api/proxy/selectPage', params)

/**
 * 新增
 */
export const addProxyIPInfo = params => post('/api/proxy/add', params)

/**
 * 更新
 */
export const updateProxyIPInfo = params => post('/api/proxy/update', params)

/**
* 删除
*/
export const deleteProxyIPInfo = params => post('/api/proxy/delete', params)


/**
* 测试代理是否有效
*/
export const testProxy = params => post('/api/proxy/test', params)

/**
* 统计查询
*/
export const BuySelectPage = params => post('/api/buy/selectPage', params)

/**
* 统计查询
*/
export const BuyQuery = params => post('/api/buy/query', params)

/**
* 邮件查询
*/
export const EmailSelectPage = params => post('/api/email/selectPage', params)

/**
* 发送邮件
*/
export const SendEmail = params => post('/api/email/send', params)
/**
* 发送邮件
*/
export const SendPersonEmail = params => post('/api/email/sendPerson', params)
/**
* 查询区
*/
export const WorldSelectPage = params => post('/api/world/selectPage', params)
/**
* 查询区
*/
export const UserSelectPage = params => post('/api/world/selectUserPage', params)

/**
* 新增区
*/
export const InsertWorld = params => post('/api/world/insert', params)
/**
*  删除区
*/
export const DeleteWorld = params => post('/api/world/delete', params)
/**
* 刷新用户
*/
export const RefreshUser = params => post('/api/world/refresh', params)
/**
* 获取刷新状态
*/
export const GetRefresh = params => post('/api/world/get', params)


/**
 * socks5 
 * 分页查询key
 */
export const selectPageSocks5 = params => post('/api/socks5/selectPage', params)

/**
 * socks5
 * 设置端口
 */
export const updatePort = params => post('/api/socks5/updatePort', params)

/**
 * socks5
 * 启动/关闭
 */
export const startOrStop = params => post('/api/socks5/startOrStop', params)
/**
 * socks5
 * switchIp
 */
export const switchIp = params => post('/api/socks5/switchIp', params)
/**
 * socks5
 * 修改备注
 */
export const updateBeizhu = params => post('/api/socks5/updateUser', params)
/**
 * socks5
 * 删除
 */
export const deleteSocks5 = params => post('/api/socks5/delete', params)

/**
* socks5
* getVcode
*/
export const getVcode = params => post('/api/socks5/getVcode', params)

/**
* socks5
* vcodeLogin
*/
export const vcodeLogin = params => post('/api/socks5/login', params)


/**
 * 币安 
 * 分页查询
 */
export const selectPageOrder = params => post('/api/binance/selectPage', params)


/**
 * 币安 
 * 查询订单
 */
 export const getOrder = params => post('/api/binance/getOrder', params)
/**
 * 币安 
 * 查询配置
 */
export const selectConfigPage = params => post('/api/binance/selectConfigPage', params)

/**
* 币安 
* 保存配置
*/
export const saveConfig = params => post('/api/binance/saveConfig', params)
/**
* 币安 
* 立刻下单
*/
export const runnow = params => post('/api/binance/runnow', params)
/**
* 币安 
* 删除配置
*/
export const deleteConfig = params => post('/api/binance/deleteConfig', params)

/**
* 币安 
* 取消配置
*/
export const cancelConfig = params => post('/api/binance/cancelConfig', params)
/**
* 币安 
* 账号信息
*/
export const binanceInfo = params => post('/api/binance/getInfo', params)

/**
* 币安 
* 保存token
*/
export const saveToken = params => post('/api/binance/saveToken', params)