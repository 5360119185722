const state = {
  userName: '',  // 用户名称
  token: '',     // token
  baseApi: '',
  number: 0, // 有效数量
  user: {},     // 用户信息
  role: '',     // 角色
  refresh: true, // 刷新
  menus: false, // 菜单
  phone: ''    // 手机号
}
const mutations = {
  setUserName(state, name) {
    state.userName = name
  },
  setNumber(state, num) {
    state.number = num
  },
  setToken(state, newToken) {
    state.token = newToken
  },
  setRole(state, role) {
    state.role = role
  },
  setRefresh(state, flag) {
    state.refresh = flag
  },
  setMenus(state, menus) {
    state.menus = menus
  },
  setPhone (state, phone) {
    state.phone = phone
    if (phone) {
      localStorage.setItem('config-user-phone', phone)
    } else {
      localStorage.removeItem('config-user-phone')
    }
  },
  setUserInfo (state, user) {
    state.user = user
  }
}
const actions = {
  // 设置name
  setUserName({ commit }, name) {
    commit('setUserName', name)
  }
}
export default {
  state,
  mutations,
  actions
}
