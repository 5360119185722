// import Config from '@/config'
import _ from 'lodash'
const getters = {
  userName: state => state.app.userName,
  token: state => state.app.token,
  refresh: state=> state.app.refresh,
  baseApi: state=> state.app.baseApi,
  role: state=> state.app.role,
  number: state=> state.app.number,
  phone: state => {
    if (!state.app.phone) {
      let phone = localStorage.getItem('config-user-phone')
      if (phone) {
        state.app.phone = phone
      }
    }
    return state.app.phone
  },
  menuTree (state) {
    // 菜单树
    let rootMenuNode = {
      id: 0,
      children: []
    }
    makeTree(rootMenuNode, state.app.menus)
    return rootMenuNode.children
  },
  menus (state) {
    return state.app.menus.map(d => {
      if (!d.parentId) {
        return d
      }
      return buildParentNode(d, state.app.menus)
    })
  },
  menuRoot (state) {
    // 根菜单
    return state.app.menus.filter(o => (_.isNil(o.parentId) || o.parentId === 'null' || o.parentId === '' || o.parentId === '0'))
  }
}

/**
 * 构造菜单树
 * @param {object} parentNode 父菜单节点
 * @param {menus[]} allMenus 菜单数组
 */
const makeTree = (parentNode, allMenus) => {
  const children = _.filter(
    allMenus,
    o => {
      if ((_.isNil(o.parentId) || o.parentId === 'null' || o.parentId === '') && parentNode.id === 0) {
        return true
      }
      return o.parentId === parentNode.id 
    }
  )
  _.forEach(children, function (child) {
    const node = {
      ...child,
      children: []
    }
    parentNode.children.push(node)
    makeTree(node, allMenus)
  })
}

// 构建父节点字段
const buildParentNode = (node, allMenus) => {
  let parent = allMenus.find(d => d.id === node.parentId)
  if (parent) {
    if (!parent.parents) {
      parent = buildParentNode(parent, allMenus)
    }
    node.parents = [...parent.parents, parent.menuName]
  } else {
    node.parents = []
  }
  return node
}

export default getters
