import Layout from '@/views/admin/common/layout'
/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    redirect: '/admin'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: { title: '登录', keepAlive: false, index: 0, anonymous: true }
  }
]

/**
 * 自定义路由
 * @type { *[] }
 */
export const customRouterMap = [
  {
    path: '/admin', // 后台
    name: 'admin',
    component: Layout,
    redirect: '/admin/index',
    children: [
      {
        path: '/admin/index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/main'),
        meta: { title: '首页', keepAlive: false, roles: ['admin', 'user', 'socks5',  "proxy", "币安"] }
      },

      {
        path: '/admin/key',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/key'),
        meta: { title: 'KEY管理', keepAlive: false, roles: ['admin'] }
      },
      {
        path: '/admin/key/list',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/key/list'),
        meta: { title: '授权管理', keepAlive: false, roles: ['admin', 'user', 'proxy'] }
      },
      {
        path: '/admin/manager/user',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/manager/user'),
        meta: { title: '用户管理', keepAlive: false, roles: ['admin'] }
      },
      {
        path: '/admin/manager/version',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/manager/version'),
        meta: { title: '版本管理', keepAlive: false, roles: ['admin'] }
      },
      {
        path: '/admin/manager/logs',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/manager/logs'),
        meta: { title: '日志查看', keepAlive: false, roles: ['admin'] }
      },
      {
        path: '/admin/proxy',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/proxy'),
        meta: { title: '代理管理', keepAlive: false, roles: ['admin'] }
      },
      {
        path: '/admin/stats/list',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/stats/list'),
        meta: { title: '购买查询', keepAlive: false, roles: ['admin'] }
      },
      {
        path: '/admin/stats/total',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/stats'),
        meta: { title: '购买统计', keepAlive: false, roles: ['admin'] }
      },
      {
        path: '/admin/email',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/email'),
        meta: { title: '邮件', keepAlive: false, roles: ['admin'] }
      },
      {
        path: '/admin/world',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/email/list'),
        meta: { title: '区管理', keepAlive: false, roles: ['admin'] }
      },
      {
        path: '/admin/socks5',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/socks5'),
        meta: { title: 'socks5管理', keepAlive: false, roles: ['admin', 'socks5'] }
      },
      {
        path: '/binance',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/binance'),
        meta: { title: '币安管理', keepAlive: false, roles: ['admin', '币安'] }
      }]
  }
]

