import Vue from 'vue'
import Router from 'vue-router'
import { constantRouterMap, customRouterMap } from './router.config.js'
import store from '../store'
import menus  from './menu.config'
import { SelectUserNumber } from '@/api'
// hack router replace callback
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

const createRouter = () =>
  new Router({
    mode: 'history', // 如果你是 history模式 需要配置vue.config.js publicPath
    // base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
  })

const router = createRouter()
router.beforeEach(async (to, from, next) => { //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) { //判断是否有标题
    document.title = to.meta.title
  }
  // 刷新配置
  if (to.path.split('/').length >= from.path.split('/').length) {
    store.commit('setRefresh', true)
  } else {
    store.commit('setRefresh', false)
  }
  if (from.meta.keepAlive) {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    from.meta.scrollTop = scrollTop;
  }
  if (to.meta.anonymous) {
    // 游客模式
    next()
    return
  }

  if (store.getters.token) {
    // 存在用户继续
    next()
    return
  }
  // 获取locationStore
  let { userid, token, personName, role } = JSON.parse(localStorage.getItem('config-user') || '{}')
  console.log(token, personName, role);
  if (token) {
    // 重置路由
    router.matcher = createRouter().matcher

    // 加载路由菜单
    let menu = loadMenu(customRouterMap, role)
    if (menu) {
      router.addRoutes(menu)
    }
    // 存在用户
    store.dispatch('setUserName', personName)
    store.commit('setToken', token)
    store.commit('setMenus', menus.filter(d => d.roles.indexOf(role) !== -1))
    store.commit('setRole', role)
    sessionStorage.setItem('token', token)
    sessionStorage.setItem('userid', userid)
    // 加载用户数量信息
    await loadUserNumber()
    next({ ...to, replace: true })
    return
  }
  console.log('=====to...', to);
  // 去登录
  sessionStorage.setItem('history-page', to.path)
  next({ path: '/login' })
})

// 加载用户信息
export const loadUserNumber = () => {
  return SelectUserNumber().then(res => {
    console.log('SelectUserNumber====>', res);
    if (res && res.code === 20000) {
      store.commit('setNumber', res.data)
    }
  })
}
// 加载自定义菜单
function loadMenu(routerData, role) {
  let result = routerData.slice(0).filter(d => {
    if (!d.meta) {
      return true
    }
    if (!d.meta.roles) {
      return true
    }
    return d.meta.roles.indexOf(role) !== -1
  }).map(d => {
    d = Object.assign({}, d)
    if (d.children) {
      let data = loadMenu(d.children, role)
      if (data) {
        d.children = data
      }
    }
    return d
  })
  return result
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
