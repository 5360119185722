export default [
  {
    "id": "1",
    "menuUri": "/admin/index",
    "menuName": "首页",
    "parentId": null,
    "roles": ["admin", "user", 'socks5', "proxy", "币安"],
    "menuIcon": "el-icon-house",
    "showCrumb": false
  },
  {
    "id": "2",
    "menuUri": "/admin/key",
    "menuName": "KEY管理",
    "parentId": null,
    "roles": ["admin"],
    "menuIcon": "el-icon-price-tag",
    "showCrumb": true
  },
  // {
  //   "id": "3",
  //   "menuUri": "/admin/key/list",
  //   "menuName": "授权管理",
  //   "parentId": null,
  //   "roles": ["admin", "user", "proxy"],
  //   "menuIcon": "el-icon-shopping-cart-2",
  //   "showCrumb": true
  // },
  // {
  //   "id": "4",
  //   "menuUri": "/admin/email",
  //   "menuName": "游戏邮件",
  //   "parentId": null,
  //   "roles": ["admin"],
  //   "menuIcon": "el-icon-message",
  //   "showCrumb": true
  // },
  // {
  //   "id": "4002",
  //   "menuUri": "/admin/email",
  //   "menuName": "发送邮件",
  //   "parentId": "4",
  //   "roles": ["admin"],
  //   "showCrumb": true
  // },
  // {
  //   "id": "4001",
  //   "menuUri": "/admin/world",
  //   "menuName": "区管理",
  //   "parentId": "4",
  //   "roles": ["admin"],
  //   "showCrumb": true
  // },
  // {
  //   "id": "6",
  //   "menuUri": "/admin/proxy",
  //   "menuName": "代理管理",
  //   "parentId": null,
  //   "roles": ["admin"],
  //   "menuIcon": "el-icon-setting",
  //   "showCrumb": true
  // },
  {
    "id": "61",
    "menuUri": "/admin/socks5",
    "menuName": "socks5管理",
    "parentId": null,
    "roles": ["admin", "socks5"],
    "menuIcon": "el-icon-setting",
    "showCrumb": true
  },
  {
    "id": "62",
    "menuUri": "/binance",
    "menuName": "币安管理",
    "parentId": null,
    "roles": ["admin", "币安"],
    "menuIcon": "el-icon-setting",
    "showCrumb": true
  },
  {
    "id": "7",
    "menuUri": "/admin/stats",
    "menuName": "游戏购买统计",
    "parentId": null,
    "roles": ["admin"],
    "menuIcon": "el-icon-s-data",
    "showCrumb": true
  },
  {
    "id": "7001",
    "menuUri": "/admin/stats/list",
    "menuName": "购买查询",
    "parentId": "7",
    "roles": ["admin"],
    "showCrumb": true
  },
  {
    "id": "7002",
    "menuUri": "/admin/stats/total",
    "menuName": "购买统计",
    "parentId": "7",
    "roles": ["admin"],
    "showCrumb": true
  },
  {
    "id": "80",
    "menuUri": "/admin/manager",
    "menuName": "系统管理",
    "parentId": null,
    "roles": ["admin"],
    "menuIcon": "el-icon-setting",
    "showCrumb": true
  },
  {
    "id": "80001",
    "menuUri": "/admin/manager/user",
    "menuName": "用户管理",
    "roles": ["admin"],
    "parentId": "80",
    "showCrumb": true
  },
  {
    "id": "80002",
    "menuUri": "/admin/manager/version",
    "menuName": "版本管理",
    "roles": ["admin"],
    "parentId": "80",
    "showCrumb": true
  },
  {
    "id": "80003",
    "menuUri": "/admin/manager/logs",
    "menuName": "日志查看",
    "roles": ["admin"],
    "parentId": "80",
    "showCrumb": true
  }
]