import Vue from 'vue'
import _ from 'lodash'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
// 全局引入按需引入UI库 elementui
import '@/plugins/elementui'
// 引入全局样式
import '@/assets/css/index.scss'
import '@/assets/css/icon/iconfont.css'
import './filters'
import Cache from '@/util/localCache'
import dict from '@/util/dict'
Vue.use(Cache)
Vue.use(dict)

/** 全局挂载lodash工具 */
Vue.prototype._ = _
Vue.prototype.$config = process.env.VUE_APP_CONFIG
Vue.config.productionTip = false
Vue.prototype.$primaryColor = 'rgb(111,206,183)'
Vue.prototype.$date = moment
// 全局事件
Vue.prototype.$event = new Vue()

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
